import { useContext, useCallback } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";

export const useSetGameStatus = () => {
  // const setSessionStatus = useCallback(
  //   async (gameSessionId, gameStatusId, gameEventTypeID) => {
  //     if (token.current === null) {
  //       await login();
  //       return setSessionStatus(gameSessionId, gameStatusId, gameEventTypeID);
  //     } else {
  //       try {
  //         const endpoint = process.env.REACT_APP_API_ENDPOINT;

  //         const response = await fetch(
  //           `${endpoint}/GameSession/${gameSessionId}`,
  //           {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionStatusId: gameStatusId,
  //             }),
  //           }
  //         );

  //         if (!response.ok) {
  //           if (response.status === 401) {
  //             await login();
  //             return setSessionStatus(
  //               gameSessionId,
  //               gameStatusId,
  //               gameEventTypeID
  //             );
  //           }
  //           const errorMessage = `Error: ${response.status} ${response.statusText}`;
  //           throw new Error(errorMessage);
  //         }

  //         const data = await response.json();
  //         // If needed, you can update the global context or handle the response data here
  //         console.log("Session status updated successfully", data);

  //         // Note: at the moment we need to do the above patch to GS to mark as playing, then the below GSE to mark as playing
  //         if (gameStatusId === 3) {
  //           await fetch(
  //             `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
  //             {
  //               method: "POST",
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token.current}`,
  //               },
  //               body: JSON.stringify({
  //                 gameSessionId: gameSessionData.gamesessionid
  //                   ? gameSessionData.gamesessionid
  //                   : 0,
  //                 gameSessionEventTypeId: gameEventTypeID,
  //                 gameData: JSON.stringify({
  //                   RFIDTagId: gameSessionData.currentrfidtagid,
  //                   Status: "Playing",
  //                 }),
  //               }),
  //             }
  //           );
  //         }
  //       } catch (error) {
  //         console.error("Error updating session status:", error.message);
  //         setError(error.message);
  //       }
  //     }
  //   },
  //   [login, gameSessionData.currentrfidtagid, gameSessionData.gamesessionid]
  // ); // Ensure all relevant dependencies are included

  // useEffect(() => {
  //     setSessionStatus();
  // }, [setSessionStatus]);

  const { gameSessionData } = useContext(GlobalContext);
  const { error, makeRequest } = useApi();

  const setSessionStatus = useCallback(
    async (gameSessionId, gameStatusId, gameEventTypeID) => {
      const data = await makeRequest(
        "PATCH",
        `GameSession/${gameSessionId}`,
        {
          gameSessionStatusId: 3,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Session status updated successfully", data);

      if (gameStatusId === 3) {
        const event = await makeRequest(
          "POST",
          `GameSessionEvent/`,
          {
            gameSessionId: gameSessionData.gamesessionid
              ? gameSessionData.gamesessionid
              : 0,
            gameSessionEventTypeId: gameEventTypeID,
            gameData: JSON.stringify({
              RFIDTagId: gameSessionData.currentrfidtagid,
              Status: "Playing",
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("GameSessionEvent created successfully", event);
      }
    },
    [
      gameSessionData.currentrfidtagid,
      gameSessionData.gamesessionid,
      makeRequest,
    ]
  );

  return { error, setSessionStatus };
};
