import loader from "../img/loader.svg";

export default function Test() {
  return (
    <div className="w-full h-full bg-black flex justify-center pt-44">
      <div className="w-40 h-10 bg-white rounded-lg mx-auto my-auto pt-1">
        <img src={loader} alt="loader" className="w-8 animate-spin mx-auto" />
      </div>
    </div>
  );
}
