import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";

// Drag and Drop lib
import { useDrag, useDrop } from "react-dnd";
import CustomDragLayer from "./CustomDragLayer";

// images
import puzzleLogo from "../images/puzzle_logo.svg";
import puzzleFrame from "../images/puzzle_frame.png";

const puzzleDataImages = [
  [
    { id: 1, src: "../images/puzzle/level-1/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-1/image_2.png", oddOneOut: true },
    { id: 3, src: "../images/puzzle/level-1/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-1/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-1/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-1/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-2/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-2/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-2/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-2/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-2/image_5.png", oddOneOut: true },
    { id: 6, src: "../images/puzzle/level-2/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-3/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-3/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-3/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-3/image_4.png", oddOneOut: true },
    { id: 5, src: "../images/puzzle/level-3/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-3/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-4/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-4/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-4/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-4/image_4.png", oddOneOut: true },
    { id: 5, src: "../images/puzzle/level-4/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-4/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-5/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-5/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-5/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-5/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-5/image_5.png", oddOneOut: true },
    { id: 6, src: "../images/puzzle/level-5/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-6/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-6/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-6/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-6/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-6/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-6/image_6.png", oddOneOut: true },
  ],
  [
    { id: 1, src: "../images/puzzle/level-7/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-7/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-7/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-7/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-7/image_5.png", oddOneOut: true },
    { id: 6, src: "../images/puzzle/level-7/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-8/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-8/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-8/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-8/image_4.png", oddOneOut: true },
    { id: 5, src: "../images/puzzle/level-8/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-8/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-9/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-9/image_2.png", oddOneOut: true },
    { id: 3, src: "../images/puzzle/level-9/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-9/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-9/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-9/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-10/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-10/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-10/image_3.png", oddOneOut: true },
    { id: 4, src: "../images/puzzle/level-10/image_4.png", oddOneOut: false },
    { id: 5, src: "../images/puzzle/level-10/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-10/image_6.png", oddOneOut: false },
  ],
  [
    { id: 1, src: "../images/puzzle/level-11/image_1.png", oddOneOut: false },
    { id: 2, src: "../images/puzzle/level-11/image_2.png", oddOneOut: false },
    { id: 3, src: "../images/puzzle/level-11/image_3.png", oddOneOut: false },
    { id: 4, src: "../images/puzzle/level-11/image_4.png", oddOneOut: true },
    { id: 5, src: "../images/puzzle/level-11/image_5.png", oddOneOut: false },
    { id: 6, src: "../images/puzzle/level-11/image_6.png", oddOneOut: false },
  ],
];

// shuffle function
const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const puzzleData = shuffleArray(puzzleDataImages);

const Game = ({
  gameData,
  gameSessionData,
  setGameData,
  endGame,
  gameTimeLimit,
  setGameCompleted,
  soundEffects,
}) => {
  const [droppedImage, setDroppedImage] = useState(null);
  // const [isCorrect, setIsCorrect] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [backgroundColour, setBackgroundColour] = useState("");
  const [countdown, setCountdown] = useState(gameTimeLimit / 1000);
  const [shuffledPuzzleData, setShuffledPuzzleData] = useState([]);
  const [gameEnd, setGameEnd] = useState(false); // Used to trigger endGame so that the latest update for "endTime" is registered

  const updateGameData = useCallback(
    (level, key, value) => {
      setGameData((prevData) => {
        const levelKey = `level_${level}`;
        const levelData = prevData[levelKey] || {
          startTime: null,
          endTime: null,
          incorrect: 0,
          correct: 0,
        };
        return {
          ...prevData,
          [levelKey]: {
            ...levelData,
            [key]:
              key === "correct" || key === "incorrect"
                ? levelData[key] + value
                : value,
          },
        };
      });
    },
    [setGameData]
  );

  useEffect(() => {
    const startTime = Date.now();
    updateGameData(currentLevel, "startTime", startTime);
    if (currentLevel > 1) {
      updateGameData(currentLevel - 1, "endTime", startTime);
    }
  }, [currentLevel, updateGameData]);

  useEffect(() => {
    if (countdown <= 0 && !gameSessionData.testing) {
      const endTime = Date.now();
      updateGameData(currentLevel, "endTime", endTime);
      setGameCompleted(true);
      setGameEnd(true);
      return;
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [
    countdown,
    currentLevel,
    endGame,
    gameSessionData.testing,
    setGameCompleted,
    updateGameData,
  ]);

  useEffect(() => {
    // Shuffle puzzle data for the current level
    if (puzzleData[currentLevel - 1]) {
      setShuffledPuzzleData(shuffleArray(puzzleData[currentLevel - 1]));
    }
  }, [currentLevel]);

  const loadNextLevel = () => {
    const endTime = Date.now();
    updateGameData(currentLevel, "endTime", endTime);

    setTimeout(() => {
      if (currentLevel < puzzleData.length) {
        setDroppedImage(null);
        setCurrentLevel(currentLevel + 1);
      } else {
        setGameCompleted(true);
        setGameEnd(true);
      }
    }, 2000);
  };

  useEffect(() => {
    if (gameEnd) {
      endGame();
    }
  }, [endGame, gameEnd]);

  const [, dropRef] = useDrop({
    accept: "image",
    drop: (item) => {
      setBackgroundColour("");
      soundEffects.puzzleDrop.play();
      setDroppedImage(item);
      setTimeout(() => {
        if (item.oddOneOut) {
          // setIsCorrect(true);
          soundEffects.correct.play();
          setBackgroundColour("bg-green-500/40");
          updateGameData(currentLevel, "correct", 1);
          loadNextLevel();
        } else {
          soundEffects.incorrect.play();
          updateGameData(currentLevel, "incorrect", 1);
          // setIsCorrect(false);
          setBackgroundColour("bg-red-500/40");
        }
      }, 500);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const renderImages = () =>
    shuffledPuzzleData.map((image) => (
      <DraggableImage
        key={image.id}
        image={image}
        pickUpSound={soundEffects.puzzlePickUp}
      />
    ));

  return (
    <div className="container mx-auto px-8 overflow-clip">
      <div className="mt-[100px] text-center">
        <span className="font-keania text-white text-[6rem]">{countdown}</span>
      </div>
      <div key={currentLevel - 1} className="grid grid-cols-3 gap-24 mt-8">
        {renderImages()}
      </div>
      <img src={puzzleLogo} alt="Puzzle Logo" className="w-2/6 mx-auto my-20" />
      <div
        className={`mx-auto transition-colors duration-500 grid grid-cols-3 gap-16`}
      >
        <div
          ref={dropRef}
          className={`transition-colors duration-500 col-start-2 col-span-1`}
        >
          {droppedImage ? (
            <div
              className="h-[320px] inset-0 bg-cover bg-center"
              style={{ backgroundImage: `url(${puzzleFrame})` }}
            >
              <motion.img
                key={droppedImage.id}
                initial={{ scale: 0.9, y: -3 }}
                exit={{ scale: 0.9, opacity: 0, y: -3 }}
                animate={{ scale: 0.96, y: -3 }}
                transition={{ type: "spring", duration: 0.2 }}
                className={`${backgroundColour} transition-colors duration-400`}
                src={droppedImage.src}
                alt="Dropped"
              />
            </div>
          ) : (
            <div
              className="h-[320px] inset-0 bg-cover bg-center"
              style={{ backgroundImage: `url(${puzzleFrame})` }}
            ></div>
          )}
        </div>
      </div>
      <div className="text-center text-[1.7rem] font-bold w-1/2 mx-auto mt-10">
        <p>
          Drag and drop the symbol that doesn't match the pattern into the box
          above.
        </p>
      </div>
      {gameSessionData.testing && (
        <button
          onClick={() => endGame()}
          className="bg-green-600 font-bold rounded-md text-white px-4 py-2 mt-10"
        >
          End game
        </button>
      )}
      <CustomDragLayer />
    </div>
  );
};

const DraggableImage = ({ image, pickUpSound }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "image",
    item: image,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      pickUpSound.play();
    }
  }, [isDragging, pickUpSound]);

  return (
    <motion.img
      initial={{ opacity: 0, y: 30 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: (1 * (image.id - 1)) / 8 },
      }} // subtract 1 from image.id so that the first image is already on-screen
      ref={dragRef}
      src={image.src}
      alt={`image-${image.id}`}
      className={`draggable ${isDragging ? "dragging opacity-35" : ""}`}
    />
  );
};

export default Game;
