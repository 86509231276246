import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import SafeGame from "./components/SafeGame";
import { GlobalContext } from "../../GlobalContext";
import { useEndGameSession } from "../../../pages/api_functions/useEndGameSession";
import { useSetGameStatus } from "../../api_functions/SetGameStatus";
import { useAbandonGame } from "../../api_functions/useAbandonGame";
import { useGetGameData } from "../../api_functions/useGetGameData";
import { motion, AnimatePresence } from "framer-motion";
import { Howl } from "howler";

import safeBgLeftVid from "./images/safe-left-anim.webm";
import safeBgRight from "./images/safe-right.jpg";
import startButton from "./images/start-button.png";
import crackTheSafeLogo from "./images/crack-the-safe-logo.svg";

import playerFoundSound from "./audio/game-start.mp3";
import startButtonSound from "./audio/start-button.mp3";
import changeDigit from "./audio/pin-select.mp3";
import pinCorrect from "./audio/pin-correct.mp3";
import pinIncorrect from "./audio/pin-incorrect.mp3";
import openSafeSound from "./audio/safe-opening.mp3";
import gameMusic from "./audio/safe-music.mp3";

const soundEffects = {
  changeDigit: new Howl({ src: [changeDigit] }),
  pinCorrect: new Howl({ src: [pinCorrect] }),
  pinIncorrect: new Howl({ src: [pinIncorrect] }),
  openSafeSound: new Howl({ src: [openSafeSound] }),
  music: new Howl({ src: [gameMusic], loop: true, volume: 0.5 }),
};

const playerFoundSFX = new Howl({ src: [playerFoundSound] });
const startButtonSFX = new Howl({ src: [startButtonSound] });

export default function CrackTheSafeGameContainer() {
  const videoRef = useRef(null);
  const { gameSessionData, showHud, socket, setGameSessionData } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [stateManager, setStateManager] = useState(0); // 0: menu 1: game 2: end
  const [gameData, setGameData] = useState({}); // data structure eg: {level_1: {startTime: null, endTime: null, incorrect: 0, correct: 0},}
  const [gameCompleted, setGameCompleted] = useState(false);
  const gameTimeLimit = 90000; // 1 minute 30 seconds
  // const gameStatusId = 7; // TODO move this to the checkin screen
  const stateRef = useRef(stateManager);

  if (stateRef.current !== stateManager) stateRef.current = stateManager;

  const tempGamePinCode = [3, 4, 1];

  // const { playerData, error: playerDataError, fetchPlayerData } = usePlayerData(gameStatusId);
  const { setSessionStatus } = useSetGameStatus();
  const { endGameSession, error: endGameSessionError } = useEndGameSession();
  const { gameStateData } = useGetGameData(3); // crack the safe gameId
  const { abandonGameSession, error: abandonError } = useAbandonGame(); // if timeout on menu screen
  // const { checkSessionStatus, sessionStatus } = useCheckSessionStatus(); // check to see if session has been abandoned via dashboard/db update

  const openSafe = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  // Play and stop music based on stateManager
  useEffect(() => {
    if (stateManager === 1) {
      soundEffects.music.play(); // Play music when game starts
    } else if (stateManager === 2) {
      soundEffects.music.fade(0.5, 0, 2000); // Fade out music over 2 seconds
    }
  }, [stateManager]);

  // redirect back to wristband search screen if no session ID is found in gameSessionData
  useEffect(() => {
    if (!gameSessionData.gamesessionid) {
      navigate(`/crack-the-safe`);
    }
  }, [gameSessionData.gamesessionid, navigate]);

  useEffect(() => {
    if (socket) {
      socket.on("game-session", (data) => {
        switch (data.type) {
          case "abandoned":
            if (
              data.game === "crackthesafe" &&
              data.gameSessionId === gameSessionData.gamesessionid
            ) {
              setGameSessionData((prevData) => ({
                ...prevData,
                alias: null,
                currentrfidtagid: null,
                gamesessionid: null,
              }));

              navigate(`/crack-the-safe`);
            }
            break;
          case "playing":
          case "completed":
            if (stateRef.current === 0) {
              window.location.href = `/crack-the-safe`;
            }
            break;
          default:
            break;
        }
      });
    }

    return () => {
      socket.off("game-session"); // Clean up the event listener
    };
  }, [gameSessionData.gamesessionid, navigate, setGameSessionData, socket]);

  useEffect(() => {
    if (stateManager === 0) {
      playerFoundSFX.play();
    }
  }, [stateManager]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && stateManager === 0) {
        startButtonSFX.play();
        setStateManager(1);
        // user has started the game, PATCH to update game status to "playing" (3)
        console.log("patch to mark game session as playing");
        // Params: game session ID, game staus ID type (2 = ready, 3 = playing, 4 = complete), game status event ID (5, 6 or 7 depending on game)
        setSessionStatus(gameSessionData.gamesessionid, 3, 7);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    if (stateManager !== 0) {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [gameSessionData.gamesessionid, setSessionStatus, stateManager]);

  const startGame = useCallback(async () => {
    setStateManager(1);
    // user has started the game, PATCH to update game status to "playing" (3)
    console.log("patch to mark game session as playing");
    // Params: game session ID, game staus ID type (2 = ready, 3 = playing, 4 = complete), game status event ID (5, 6 or 7 depending on game)
    setSessionStatus(gameSessionData.gamesessionid, 3, 7);
  }, [gameSessionData.gamesessionid, setSessionStatus]);

  const endGame = useCallback(async () => {
    setStateManager(2);
    // Params: Game Data ID, Game Status No, Game Data
    endGameSession(3, 7, {
      RFIDTagId: gameSessionData.currentrfidtagid
        ? gameSessionData.currentrfidtagid
        : 0,
      PuzzleData: {
        Completed: gameCompleted,
        PinCorrect: gameData.correctPinGuess === 1,
        IncorrectPinAttempts: gameData.incorrectPinGuess,
        StartTime: gameData.startTime,
        EndTime: gameData.endTime,
        CompletionSeconds: Math.floor(
          (gameData.endTime - gameData.startTime) / 1000
        ),
      },
    });
  }, [
    gameCompleted,
    gameData,
    endGameSession,
    gameSessionData.currentrfidtagid,
  ]);

  return (
    <div>
      {abandonError && (
        <div className="w-[33%] mx-auto text-white bg-red-600 rounded-md p-8 absolute top-0 z-[999]">
          <p className="font-bold text-[1.2rem]">
            There was an issue abandoning The Game
          </p>
          <p>{abandonError}</p>
        </div>
      )}
      {endGameSessionError && (
        <div className="w-[33%] mx-auto text-white bg-red-600 rounded-md p-8 absolute top-0 z-[999">
          <p className="font-bold text-[1.2rem]">Error Posting Game Data</p>
          <p>{endGameSessionError}</p>
        </div>
      )}
      <AnimatePresence>
        {stateManager === 0 && (
          <Menu
            startGame={startGame}
            gameSessionData={gameSessionData}
            gameStateData={gameStateData}
            abandonGameSession={abandonGameSession}
          />
        )}
        {stateManager === 1 && (
          <SafeGame
            gamePinCode={tempGamePinCode}
            gameData={gameData}
            setGameData={setGameData}
            endGame={endGame}
            gameTimeLimit={gameTimeLimit}
            setGameCompleted={setGameCompleted}
            openSafe={openSafe}
            soundEffects={soundEffects}
          />
        )}
        {stateManager === 2 && (
          <>
            <End showHud={showHud} />
          </>
        )}
        {endGameSessionError && (
          <div>Error posting game event: {endGameSessionError}</div>
        )}
        <Safe stateManager={stateManager} videoRef={videoRef} />
      </AnimatePresence>
    </div>
  );
}

export function Safe({ stateManager, videoRef }) {
  const variants = {
    initial: {},
    closed: {
      x: 0,
      transition: {
        duration: 1.6,
      },
    },
    open: {
      x: 1344,
      transition: {
        duration: 1.6,
      },
    },
  };

  return (
    <>
      <motion.div
        key="vault_bg"
        variants={variants}
        initial="initial"
        animate={stateManager !== 1 ? "closed" : "open"}
        className="absolute bg-white/20 w-screen h-screen top-0 left-0 z-[1] backdrop-filter backdrop-blur-md"
      ></motion.div>
      <div className="flex flex-row w-full -z-1 absolute top-0 left-0">
        <div className="">
          {/* <img src={safeBgLeft} className="w-full h-screen" /> */}
          <video ref={videoRef} className="" preload="auto" controls={false}>
            <source src={safeBgLeftVid} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="">
          <img src={safeBgRight} alt="" className="" />
        </div>
      </div>
    </>
  );
}

export function Menu({
  startGame,
  gameSessionData,
  gameStateData,
  abandonGameSession,
}) {
  // auto time out after 5 mins
  const [countdown, setCountdown] = useState(300);

  useEffect(() => {
    if (gameStateData?.timeOut) {
      setCountdown(gameStateData.timeOut);
    }
  }, [gameStateData]);

  useEffect(() => {
    if (countdown === 0) {
      abandonGameSession("crack-the-safe");
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, abandonGameSession]);

  return (
    <>
      <motion.div
        key="menu"
        initial={{}}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        className="container mx-auto min-h-screen flex flex-col items-center justify-center space-y-32 z-10 relative"
      >
        <img src={crackTheSafeLogo} alt="" className="w-1/4" />
        <div className="w-1/2 text-center">
          <p className="text-[1.6rem] font-bold mb-6">
            Can you crack the code
            {gameSessionData?.alias && (
              <>
                ,{" "}
                <span className="text-[#1DE0FE]">{gameSessionData.alias}</span>
              </>
            )}
            ?
          </p>
          <p className="text-[1.6rem] font-bold mb-6">
            Look at the clues on your digital tablet and see if you can work out
            the 3 digit code to open the safe.
          </p>
          <p className="text-[1.6rem] font-bold mb-6">
            Use the dial to select each digit. Press the dial in the confirm the
            digit and move on to the next one.
          </p>
        </div>
        {/* <button onClick={() => setStateManager(1)} className='w-1/4 mx-auto rounded-lg bg-emerald-600 hover:bg-emerald-400 active:bg-orange-400 py-2' >Begin</button> */}
        <div className="font-digital uppercase text-[1.8rem] text-[#1DE0FE] relative">
          <img
            src={startButton}
            alt=""
            className="w-[449px] h-[79px] absolute left-0 top-0"
          />
          <span className="z-30 relative top-5 mx-8 -left-2">
            push dial in to start
          </span>
        </div>
      </motion.div>
    </>
  );
}

// return to wristband search screen after 5 seconds
export function End({ showHud }) {
  const [countdown, setCountdown] = useState(5); // seconds

  useEffect(() => {
    if (countdown === 0 && !showHud) {
      window.location.href = `/crack-the-safe`;
      // TODO: confirm whether game data / wristband data should be cleared in anticipation of next player
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 2000);

    return () => clearInterval(timer);
  }, [countdown, showHud]);

  return (
    <>
      <div className="container mx-auto min-h-screen flex flex-col items-center justify-center z-10 relative">
        <h1 className="font-digital uppercase text-[4rem] text-white">
          Challenge Completed
        </h1>
        <p className="font-digital uppercase text-[2rem] mb-8">
          Continue to the next challenge.
        </p>
        <p className="text-[#1DE0FE] text-[1.8rem]">
          Redirecting in {countdown} seconds...
        </p>
      </div>
    </>
  );
}
