import { useContext, useCallback } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";

export const useAbandonGame = () => {
  const { gameSessionData } = useContext(GlobalContext);
  const { error, makeRequest } = useApi();

  // const abandonGameSession = useCallback(
  //   async (gameName) => {
  //     if (token.current === null) {
  //       await login();
  //       return abandonGameSession(gameName);
  //     } else {
  //       const gameStatusIds = {
  //         puzzle: 5,
  //         firewall: 6,
  //         "crack-the-safe": 7,
  //       };

  //       try {
  //         // Call to set status as complete
  //         const patchStatusUpdate = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSession/${gameSessionData.gamesessionid}`,
  //           {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionStatusId: 5, // 5 = abandoned
  //             }),
  //           }
  //         );

  //         if (!patchStatusUpdate.ok) {
  //           if (patchStatusUpdate.status === 401) {
  //             await login();
  //             return abandonGameSession(gameName);
  //           }
  //           throw new Error(
  //             `Error: ${patchStatusUpdate.status} ${patchStatusUpdate.statusText}`
  //           );
  //         } else {
  //           console.log("game has been marked as abandoned");
  //         }

  //         const postStatusAbandoned = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionId: gameSessionData.gamesessionid, // the current game session ID
  //               gameSessionEventTypeId: gameStatusIds[gameName], // this puzzle = 5, firewall = 6, crack-the-safe = 7, thegetaway = 8
  //               gameData: JSON.stringify({
  //                 RFIDTagId: gameSessionData.currentrfidtagid, // that user's RFID tag ID
  //                 Status: "Abandoned",
  //               }),
  //             }),
  //           }
  //         );

  //         if (!postStatusAbandoned.ok) {
  //           throw new Error(
  //             `Error: ${postStatusAbandoned.status} ${postStatusAbandoned.statusText}`
  //           );
  //         } else {
  //           console.log("game has been marked as abandoned");
  //           window.location.href = `/${gameName}`; // redirect to look for next player
  //         }
  //       } catch (error) {
  //         setError(error.message);
  //         console.error("Error during API calls:", error);
  //       }
  //     }
  //   },
  //   [gameSessionData.gamesessionid, login, gameSessionData.currentrfidtagid]
  // );

  const abandonGameSession = useCallback(
    async (gameName) => {
      const gameStatusIds = {
        puzzle: 5,
        firewall: 6,
        "crack-the-safe": 7,
      };
      await makeRequest(
        "PATCH",
        `GameSession/${gameSessionData.gamesessionid}`,
        {
          gameSessionStatusId: 5,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("game has been marked as abandoned");
      await makeRequest(
        "POST",
        `GameSessionEvent/`,
        {
          gameSessionId: gameSessionData.gamesessionid,
          gameSessionEventTypeId: gameStatusIds[gameName],
          gameData: JSON.stringify({
            RFIDTagId: gameSessionData.currentrfidtagid,
            Status: "Abandoned",
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("abandon event created successfully");
      window.location.href = `/${gameName}`;
    },
    [
      makeRequest,
      gameSessionData.gamesessionid,
      gameSessionData.currentrfidtagid,
    ]
  );

  return { abandonGameSession, error };
};
