import { useContext, useCallback } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";

export const useEndGameSession = () => {
  const { gameSessionData } = useContext(GlobalContext);
  const { error, makeRequest } = useApi();

  // const endGameSession = useCallback(
  //   async (gameId, gameEventTypeID, gameData) => {
  //     if (token.current === null) {
  //       await login();
  //       return endGameSession(gameId, gameEventTypeID, gameData);
  //     } else {
  //       try {
  //         // Post game data
  //         const postGameDataResponse = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionId: gameSessionData.gamesessionid
  //                 ? gameSessionData.gamesessionid
  //                 : 0,
  //               gameSessionEventTypeId: gameId,
  //               gameData: JSON.stringify(gameData),
  //             }),
  //           }
  //         );

  //         if (!postGameDataResponse.ok) {
  //           if (postGameDataResponse.status === 401) {
  //             await login();
  //             return endGameSession(gameId, gameEventTypeID, gameData);
  //           }
  //           throw new Error(
  //             `Error: ${postGameDataResponse.status} ${postGameDataResponse.statusText}`
  //           );
  //         } else {
  //           // console.log("Game session event successfully posted game data")
  //         }

  //         // Mark session as complete
  //         const postStatusResponse = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSessionEvent/`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionId: gameSessionData.gamesessionid
  //                 ? gameSessionData.gamesessionid
  //                 : 0,
  //               gameSessionEventTypeId: gameEventTypeID,
  //               gameData: JSON.stringify({
  //                 RFIDTagId: gameSessionData.currentrfidtagid,
  //                 Status: "Complete",
  //               }),
  //             }),
  //           }
  //         );

  //         if (!postStatusResponse.ok) {
  //           throw new Error(
  //             `Error: ${postStatusResponse.status} ${postStatusResponse.statusText}`
  //           );
  //         } else {
  //           // console.log("Game session event successfully updated status to complete")
  //         }

  //         // Call to set status as complete
  //         const patchStatusUpdate = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSession/${gameSessionData.gamesessionid}`,
  //           {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //             body: JSON.stringify({
  //               gameSessionStatusId: 4, // 4 = complete
  //             }),
  //           }
  //         );

  //         if (!patchStatusUpdate.ok) {
  //           throw new Error(
  //             `Error: ${patchStatusUpdate.status} ${patchStatusUpdate.statusText}`
  //           );
  //         } else {
  //           // console.log("successfully patched game session id to be complete")
  //         }

  //         // Call do game engine
  //         const doGameEngineResponse = await fetch(
  //           `${process.env.REACT_APP_API_ENDPOINT}/GameSession/DoGameEngine/${gameSessionData.gamesessionid}`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //           }
  //         );

  //         if (!doGameEngineResponse.ok) {
  //           throw new Error(
  //             `Error: ${doGameEngineResponse.status} ${doGameEngineResponse.statusText}`
  //           );
  //         } else {
  //           console.log("successfully run DoGameEngine");
  //         }

  //         console.log("All API calls were successful.");
  //       } catch (error) {
  //         setError(error.message);
  //         console.error("Error during API calls:", error);
  //       }
  //     }
  //   },
  //   [gameSessionData.currentrfidtagid, gameSessionData.gamesessionid, login]
  // );

  const endGameSession = useCallback(
    async (gameId, gameEventTypeID, gameData) => {
      // Post game data
      await makeRequest(
        "POST",
        `GameSessionEvent/`,
        {
          gameSessionId: gameSessionData.gamesessionid
            ? gameSessionData.gamesessionid
            : 0,
          gameSessionEventTypeId: gameId,
          gameData: JSON.stringify(gameData),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("GameData posted");

      // Send complete event
      await makeRequest(
        "POST",
        `GameSessionEvent/`,
        {
          gameSessionId: gameSessionData.gamesessionid
            ? gameSessionData.gamesessionid
            : 0,
          gameSessionEventTypeId: gameEventTypeID,
          gameData: JSON.stringify({
            RFIDTagId: gameSessionData.currentrfidtagid,
            Status: "Complete",
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("GameSessionEvent created successfully");

      // Mark session as complete
      await makeRequest(
        "PATCH",
        `GameSession/${gameSessionData.gamesessionid}`,
        {
          gameSessionStatusId: 4,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Session status updated successfully");

      // Call game engine
      await makeRequest(
        "POST",
        `GameSession/DoGameEngine/${gameSessionData.gamesessionid}`
      );
      console.log("Game engine run successfully");
    },
    [
      gameSessionData.currentrfidtagid,
      gameSessionData.gamesessionid,
      makeRequest,
    ]
  );

  return { endGameSession, error };
};
