import React from 'react'
import { Link } from 'react-router-dom'

export default function ChallengeSelect() {

    return(
        <div className='container mx-auto min-h-screen flex flex-col items-center justify-center'>
            <button onClick={() => {
                    throw new Error("Sentry Test Error");
                }}>Break the world</button>
            <h1 className='mb-12'>Select Challenge</h1>
            <div className='flex flex-col text-center space-y-8'>
                <Link to="/puzzle" className='bg-black/20 w-full py-8 text-2xl px-8 active:bg-black/30 transition-opacity'>PUZZLE</Link>
                <Link to="/firewall" className='bg-black/20 w-full py-8 text-2xl px-8 active:bg-black/30 transition-opacity'>FIREWALL</Link>
                <Link to="/crack-the-safe" className='bg-black/20 w-full py-8 text-2xl px-8 active:bg-black/30 transition-opacity'>CRACK THE SAFE</Link>
            </div>
        </div>
    )
}