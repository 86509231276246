// usePulse.js
import { useEffect, useRef, useContext, useCallback, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";

function getGameId(id) {
  if (id) {
    switch (id) {
      case "puzzle":
        return 1;
      case "firewall":
        return 2;
      case "crack-the-safe":
        return 3;
      default:
    }
  }
  return null;
}
function returnStatus(statusId) {
  if (statusId) {
    switch (statusId) {
      case 1:
        return "ready";
      case 2:
        return "playing";
      case 3:
        return "ended";
      default:
    }
  }
  return null;
}

export const usePulse = (gameString, gameSessionId, sessionStatus) => {
  const intervalRef = useRef(null);
  const { socket } = useContext(GlobalContext);

  // const startPulse = () => {
  //   if (!intervalRef.current) {
  //     const gameId = getGameId(gameString);
  //     const statusLabel = returnStatus(sessionStatus);

  //     intervalRef.current = setInterval(() => {
  //       fetch(`${process.env.REACT_APP_API_ENDPOINT}/debug/pulse`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token.current}`,
  //         },
  //         body: JSON.stringify({ gameId, gameSessionId, statusLabel }),
  //       })
  //         .then((response) => {
  //           if (!response.ok) {
  //             throw new Error(`Error posting pulse: ${response.statusText}`);
  //           }
  //           // Optionally handle the response data
  //         })
  //         .catch((error) => {
  //           console.error("Error posting pulse:", error);
  //         });
  //     }, 1000);
  //   }
  // };

  const [isDebugging, setIsDebugging] = useState(false);

  const { makeRequest } = useApi();

  const fetchDebugConfig = useCallback(async () => {
    try {
      const settings = await makeRequest("GET", `debug/pulse`);
      if (settings && settings.value === "true") {
        setIsDebugging(true);
      }
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  }, [makeRequest]);

  useEffect(() => {
    fetchDebugConfig();
  }, [fetchDebugConfig]);

  useEffect(() => {
    if (isDebugging) {
      console.log("Starting debug polling");
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      const gameId = getGameId(gameString);
      const statusLabel = returnStatus(sessionStatus);

      intervalRef.current = setInterval(() => {
        makeRequest(
          "POST",
          `debug/pulse`,
          {
            gameId,
            gameSessionId,
            statusLabel,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }, 1000);
    } else {
      console.log("Stopping debug polling");
      clearInterval(intervalRef.current);
    }

    // Cleanup on unmount
    return () => clearInterval(intervalRef.current);
  }, [gameSessionId, gameString, isDebugging, makeRequest, sessionStatus]);

  useEffect(() => {
    if (socket) {
      socket.on("debug-pulse-update", (data) => {
        if (data.value === "true") {
          setIsDebugging(true);
        } else {
          setIsDebugging(false);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("debug-pulse-update");
      }
    };
  }, [socket]);

  // useEffect(() => {
  //   let isMounted = true;

  //   // Fetch initial pulse status
  //   fetch(`${process.env.REACT_APP_API_ENDPOINT}/debug/pulse`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token.current}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(
  //           `Error fetching pulse status: ${response.statusText}`
  //         );
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (isMounted && data.value === "true") {
  //         startPulse();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching pulse status:", error);
  //     });

  //   // Listen for pulse updates via socket
  //   if (socket) {
  //     socket.on("debug-pulse-update", (data) => {
  //       if (data.value === "true") {
  //         startPulse();
  //       } else {
  //         stopPulse();
  //       }
  //     });
  //   }

  //   // Cleanup on unmount
  //   return () => {
  //     isMounted = false;
  //     stopPulse();

  //     if (socket) {
  //       socket.off("debug-pulse-update");
  //     }
  //   };
  // }, [socket, gameString]);

  // Return nothing since this hook doesn't need to expose any values
};
