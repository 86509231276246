import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [showHud, setShowHud] = useState(false);
  const [gameSessionData, setGameSessionData] = useState({
    alias: "",
    currentrfidtagid: null,
    gamesessionid: null,
    testing: false,
  });
  const [socket, setSocket] = useState(null);

  //   const socket = io(process.env.REACT_APP_SOCKETS); // Initialize the socket connection

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_SOCKETS);
      setSocket(newSocket);
    } else {
      socket.on("connect", () => {
        console.log("Connected to socket server");
      });
    }
    return () => {
      if (socket) {
        socket.disconnect(); // Clean up the socket when the component unmounts
      }
    };
  }, [socket]);

  return (
    <GlobalContext.Provider
      value={{
        showHud,
        setShowHud,
        gameSessionData,
        setGameSessionData,
        socket,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
