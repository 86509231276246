import { useCallback, useEffect, useRef, useState } from "react";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const useApi = (options = {}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const retryRequest = useRef(null);

  const login = async () => {
    const response = await fetch(`${baseUrl}/auth/admin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: "playmaxx-admin@2dm.net.au",
        password: process.env.REACT_APP_AUTH_PASSWORD,
      }),
    });
    const data = await response.json();
    setToken(data.token);
  };

  const makeRequest = useCallback(async (method, url, body, options = {}) => {
    setLoading(true);
    try {
      //   console.log("body", body);
      //   console.log("token", localStorage.getItem("token"));
      const response = await fetch(`${baseUrl}/${url}`, {
        method: method,
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const data = await response.json().catch(() => null);
        console.log("response", data);
        return data;
      } else if (response.status === 401) {
        if (!retryRequest.current) {
          retryRequest.current = {
            method,
            url,
            body,
            options,
          };
        }
        setError("Unauthorized");
      }
    } catch (error) {
      console.log("error", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // Update localstorage
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  // Trigger retries
  useEffect(() => {
    if (!token) {
      login();
    } else if (retryRequest.current) {
      const { method, url, body, options } = retryRequest.current;
      makeRequest(method, url, body, options, false);
    }
  }, [makeRequest, token]);

  // Login and retry request on 401
  useEffect(() => {
    if (error === "Unauthorized" && !!retryRequest.current) {
      login();
    }
  }, [error]);

  return { error, loading, makeRequest };
};

export default useApi;
