import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import PuzzleGame from "./components/PuzzleGame";
import { GlobalContext } from "../../GlobalContext";
// import { usePlayerData } from "../../../pages/api_functions/RequestPlayerData";
import { useEndGameSession } from "../../../pages/api_functions/useEndGameSession";
import { useSetGameStatus } from "../../api_functions/SetGameStatus";
import { useAbandonGame } from "../../api_functions/useAbandonGame";
import { useCheckSessionStatus } from "../../api_functions/useCheckSessionStatus";
import { Howl } from "howler";

import { motion } from "framer-motion";

// audio
import startAudio from "./audio/puzzle-start.mp3";
import correctAudio from "./audio/correct.mp3";
import incorrectAudio from "./audio/incorrect.mp3";
import pickUpAudio from "./audio/pick-up.mp3";
import dropAudio from "./audio/put-down.mp3";
import gameMusic from "./audio/puzzle-music.mp3";

// images
import puzzleLogo from "./images/puzzle_logo.svg";
import puzzleBackground from "./images/puzzle-background.png";
import puzzleButton from "./images/puzzle-button.svg";
import { useGetGameData } from "../../api_functions/useGetGameData";

const soundEffects = {
  start: new Howl({ src: [startAudio] }),
  correct: new Howl({ src: [correctAudio] }),
  incorrect: new Howl({ src: [incorrectAudio] }),
  puzzlePickUp: new Howl({ src: [pickUpAudio] }),
  puzzleDrop: new Howl({ src: [dropAudio] }),
  music: new Howl({ src: [gameMusic], loop: true, volume: 0.5 }),
};

export default function PuzzleGameContainer() {
  const { gameSessionData, showHud, socket, setGameSessionData } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [stateManager, setStateManager] = useState(0); // 0: menu 1: game 2: end
  const [gameData, setGameData] = useState({}); // data structure eg: {level_1: {startTime: null, endTime: null, incorrect: 0, correct: 0},}
  const [gameCompleted, setGameCompleted] = useState(false);
  const gameTimeLimit = 60000; // 1 minute
  const stateRef = useRef(stateManager);

  if (stateRef.current !== stateManager) stateRef.current = stateManager;

  const { setSessionStatus } = useSetGameStatus();
  const { endGameSession, error: endGameSessionError } = useEndGameSession();
  const { gameStateData } = useGetGameData(1); // puzzle gameId
  const { abandonGameSession, error: abandonError } = useAbandonGame(); // if timeout on menu screen
  const { checkSessionStatus, sessionStatus } = useCheckSessionStatus(); // check to see if session has been abandoned via dashboard/db update

  // redirect back to wristband search screen if no session ID is found in gameSessionData
  useEffect(() => {
    if (!gameSessionData.gamesessionid) {
      navigate(`/puzzle`);
    }
  }, [navigate, gameSessionData.gamesessionid]);

  // check that the game hasn't been abandoned
  useEffect(() => {
    if (socket) {
      socket.on("game-session", (data) => {
        switch (data.type) {
          case "abandoned":
            if (
              data.game === "puzzle" &&
              data.gameSessionId === gameSessionData.gamesessionid
            ) {
              setGameSessionData((prevData) => ({
                ...prevData,
                alias: null,
                currentrfidtagid: null,
                gamesessionid: null,
              }));

              navigate(`/puzzle`);
            }
            break;
          case "playing":
          case "completed":
            if (stateRef.current === 0) {
              window.location.href = `/puzzle`;
            }
            break;
          default:
            break;
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("game-session"); // Clean up the event listener
      }
    };
  }, [socket, gameSessionData.gamesessionid, navigate, setGameSessionData]);

  // Play and stop music based on stateManager
  useEffect(() => {
    if (stateManager === 1) {
      soundEffects.music.play(); // Play music when game starts
    } else if (stateManager === 2) {
      soundEffects.music.fade(0.5, 0, 2000); // Fade out music over 2 seconds
    }
  }, [stateManager]);

  const startGame = useCallback(async () => {
    setStateManager(1);
    // user has started the game, PATCH to update game status to "playing" (3)

    // Params: game session ID, game staus ID type (2 = ready, 3 = playing, 4 = complete), game status event ID (5, 6 or 7 depending on game)
    setSessionStatus(gameSessionData.gamesessionid, 3, 5);
  }, [gameSessionData.gamesessionid, setSessionStatus]);

  const endGame = useCallback(async () => {
    setStateManager(2);
    // Params: Game Data ID, Game Status No, Game Data
    endGameSession(1, 5, {
      RFIDTagId: gameSessionData.currentrfidtagid
        ? gameSessionData.currentrfidtagid
        : 0,
      PuzzleData: {
        PuzzleCompleted: {
          Completed: gameCompleted, // will be false if game times out
          IncorrectInputs: Object.values(gameData).reduce(
            (acc, curr) => acc + curr.incorrect,
            0
          ),
          CorrectInputs: Object.values(gameData).reduce(
            (acc, curr) => acc + curr.correct,
            0
          ),
          startTime: gameData.startTime,
          endTime: gameData.endTime,
          CompletionSeconds: Math.floor(
            (Object.entries(gameData)[Object.entries(gameData).length - 1][1]
              .endTime -
              Object.entries(gameData)[0][1].startTime) /
              1000
          ),
        },
      },
    });
  }, [endGameSession, gameCompleted, gameData, gameSessionData]);

  return (
    <div className={`w-full h-screen overflow-hidden relative`}>
      {abandonError && (
        <div className="w-[33%] mx-auto text-white bg-red-600 rounded-md p-8 absolute top-0 z-[999]">
          <p className="font-bold text-[1.2rem]">
            There was an issue abandoning The Game
          </p>
          <p>{abandonError}</p>
        </div>
      )}
      {endGameSessionError && (
        <div className="w-[33%] mx-auto text-white bg-red-600 rounded-md p-8 absolute top-0 z-[999">
          <p className="font-bold text-[1.2rem]">Error Posting Game Data</p>
          <p>{endGameSessionError}</p>
        </div>
      )}
      <div
        className={`absolute inset-0 bg-cover bg-center before:absolute before:inset-0 before:bg-black -z-10 transition-opacity duration-1000 ${
          stateManager === 1 ? "before:opacity-0" : "before:opacity-50"
        }`}
        style={{ backgroundImage: `url(${puzzleBackground})` }}
      ></div>
      {stateManager === 0 && (
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: 0 }}
          exit={{ x: -100, transition: { duration: 0.2 } }}
        >
          <Menu
            startGame={startGame}
            gameSessionData={gameSessionData}
            startSound={soundEffects.start}
            abandonGameSession={abandonGameSession}
            gameStateData={gameStateData}
            checkSessionStatus={checkSessionStatus}
            sessionStatus={sessionStatus}
          />
        </motion.div>
      )}
      {stateManager === 1 && (
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0, transition: { duration: 0.4 } }}
          exit={{ x: -100 }}
        >
          <PuzzleGame
            gameData={gameData}
            gameSessionData={gameSessionData}
            setGameData={setGameData}
            endGame={endGame}
            gameTimeLimit={gameTimeLimit}
            setGameCompleted={setGameCompleted}
            soundEffects={soundEffects}
          />
        </motion.div>
      )}
      {stateManager === 2 && <End showHud={showHud} />}
      {endGameSessionError && (
        <div>Error posting game event: {endGameSessionError}</div>
      )}
    </div>
  );
}

export function Menu({
  startGame,
  gameSessionData,
  startSound,
  gameStateData,
  abandonGameSession,
}) {
  // auto time out after 5 mins
  const [countdown, setCountdown] = useState(300);

  useEffect(() => {
    if (gameStateData?.timeOut) {
      setCountdown(gameStateData.timeOut);
    }
  }, [gameStateData]);

  useEffect(() => {
    if (countdown === 0) {
      abandonGameSession("puzzle");
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, abandonGameSession]);

  const handleStartClick = () => {
    startSound.play();
    startGame();
  };

  return (
    <>
      <div className="container h-screen mx-auto flex flex-col items-center place-content-center">
        <div className="flex flex-col text-center items-center space-y-24">
          <motion.img
            initial={{ y: 100, scale: 0.8, opacity: 0.4 }}
            animate={{
              y: 0,
              scale: 1,
              opacity: 1,
              animation: { duration: 0.8 },
            }}
            src={puzzleLogo}
            className=""
          />

          <div className="w-3/4 space-y-14 text-[2.4rem] font-bold uppercase">
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}
            >
              Can you crack the code
              {gameSessionData?.alias && (
                <>
                  ,<br />
                  <span className="text-[#f58c21]">
                    {gameSessionData.alias}
                  </span>
                </>
              )}
              ?
            </motion.p>
            <motion.p
              initial={{ x: 200, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}
            >
              Identify the odd symbol out in each puzzle.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0 } }}
            >
              There are 7 levels to complete.
            </motion.p>
          </div>
          <motion.button
            onClick={handleStartClick}
            initial={{ opacity: 0, y: -30 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.8, duration: 1 },
            }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="text-[2rem] uppercase font-bold inset-0 bg-cover bg-center px-[82px] py-[26px] border-solid border border-white active:bg-green-100/30"
            style={{ backgroundImage: `url(${puzzleButton})` }}
          >
            Start
          </motion.button>
        </div>
      </div>
    </>
  );
}

// return to wristband search screen after 5 seconds
export function End({ showHud }) {
  const [countdown, setCountdown] = useState(5); // seconds

  useEffect(() => {
    if (countdown === 0 && !showHud) {
      window.location.href = `/puzzle`;
      // TODO: confirm whether game data / wristband data should be cleared in anticipation of next player
    }

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 2000);

    return () => clearInterval(timer);
  }, [countdown, showHud]);

  return (
    <>
      <div className="container mx-auto min-h-screen flex flex-col items-center justify-center space-y-12">
        <h1 className="font-keania text-white text-[5rem] uppercase">
          Challenge Completed.
        </h1>
        <p className="font-bold text-white text-[2.4rem]">
          Please continue to the next challenge.
        </p>
        <p className=" text-white text-[1.8rem]">
          Redirecting in <span className="font-bold">{countdown}</span>{" "}
          seconds...
        </p>
      </div>
    </>
  );
}
