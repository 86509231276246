import { useState, useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";
import { useCallback } from "react";

export const useCheckSessionStatus = () => {
  const [sessionStatus, setSessionStatus] = useState(null);
  const { gameSessionData } = useContext(GlobalContext);
  const { error, makeRequest } = useApi();

  // const checkSessionStatus = useCallback(async () => {
  //   if (token.current === null) {
  //     await login();
  //     return checkSessionStatus();
  //   } else {
  //     try {
  //       const endpoint = process.env.REACT_APP_API_ENDPOINT;

  //       const response = await fetch(
  //         `${endpoint}/gameSession/${gameSessionData.gamesessionid}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token.current}`,
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         if (response.status === 401) {
  //           await login();
  //           return checkSessionStatus();
  //         }
  //         const errorMessage = `Error: ${response.status} ${response.statusText}`;
  //         throw new Error(errorMessage);
  //       }

  //       const responseData = await response.json();
  //       // console.log(responseData.gameSessionStatus.name)
  //       setSessionStatus(responseData);
  //     } catch (error) {
  //       console.error("Error updating session status:", error.message);
  //       // setError(error.message);
  //     }
  //   }
  // }, [gameSessionData.gamesessionid, login]); // Ensure all relevant dependencies are included

  const checkSessionStatus = useCallback(async () => {
    const data = await makeRequest(
      "GET",
      `GameSession/${gameSessionData.gamesessionid}`
    );
    console.log("session status", data);
    setSessionStatus(data);
  }, [gameSessionData.gamesessionid, makeRequest]);

  return { checkSessionStatus, sessionStatus, error };
};
