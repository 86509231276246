import { useState, useContext, useCallback } from "react";
import { GlobalContext } from "../GlobalContext";
import useApi from "./useApi";

const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const useGetGameData = (gameId) => {
  const [error, setError] = useState(null);
  const [gameStateData, setGameStateData] = useState(null);
  const { gameSessionData } = useContext(GlobalContext);
  const { error: apiError, makeRequest } = useApi();

  // const getGameData = useCallback(async () => {
  //   if (token.current === null) {
  //     await login();
  //     return getGameData();
  //   } else {
  //     try {
  //       const endpoint = process.env.REACT_APP_API_ENDPOINT;

  //       const response = await fetch(
  //         `${endpoint}/GameSetting?gameSeasonId=${gameId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token.current}`,
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         const errorMessage = `Error: ${response.status} ${response.statusText}`;

  //         if (response.status === 401) {
  //           await login();
  //           return getGameData();
  //         }

  //         throw new Error(errorMessage);
  //       }

  //       const responseData = await response.json();

  //       if (gameId === 2) {
  //         // firewall also needs questions and pin
  //         const responsePin = await fetch(
  //           `${endpoint}/GameSessionSetting?gameSessionId=${gameSessionData.gamesessionid}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token.current}`,
  //             },
  //           }
  //         );

  //         if (!responsePin.ok) {
  //           const errorMessage = `Error: ${responsePin.status} ${responsePin.statusText}`;
  //           throw new Error(errorMessage);
  //         }

  //         const pinNumber = await responsePin.json();

  //         setGameStateData({
  //           questionsData: shuffleArray(
  //             JSON.parse(responseData.items[0].settingData).questions
  //           ),
  //           pin: JSON.parse(pinNumber.items[0].sessionSettings)["PIN"].split(
  //             ""
  //           ),
  //           timeOut: JSON.parse(responseData.items[0].settingData).timeLimits
  //             .readyTimeout, // time is in seconds
  //         });
  //       } else {
  //         setGameStateData({
  //           timeOut: JSON.parse(responseData.items[0].settingData).timeLimits
  //             .readyTimeout, // time is in seconds
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error updating session status:", error.message);
  //       setError(error.message);
  //     }
  //   }
  // }, [login, gameId, gameSessionData.gamesessionid]); // Ensure all relevant dependencies are included

  const getGameData = useCallback(async () => {
    const data = await makeRequest("GET", `GameSetting?gameSeasonId=${gameId}`);
    console.log("game settings", data);
    try {
      const settings = JSON.parse(data.items[0].settingData);
      if (gameId === 2) {
        // firewall also needs questions and pin
        const pinData = await makeRequest(
          "GET",
          `GameSessionSetting?gameSessionId=${gameSessionData.gamesessionid}`
        );
        console.log("game pin", pinData);
        setGameStateData({
          questionsData: shuffleArray(settings.questions),
          pin: JSON.parse(
            pinData.items[0].sessionSettings.toLowerCase()
          ).pin.split(""),
          timeOut: settings.timeLimits.readyTimeout, // time is in seconds
        });
      } else {
        setGameStateData({
          timeOut: settings.timeLimits.readyTimeout, // time is in seconds
        });
      }
    } catch (error) {
      console.error("Error updating session status:", error.message);
      setError(error.message);
    }
  }, [gameId, gameSessionData.gamesessionid, makeRequest]);

  return { error, apiError, getGameData, gameStateData };
};
